import type { FC, ReactNode } from 'react';
import React, { memo } from 'react';

import { Button } from '@sravni/react-design-system';
import { useIsMobile } from '@sravni/react-utils';

import { AmountControl } from '@src/components/ui/AmountControlAB';
import { RUSSIA_AMOUNT_FIELD_SETTINGS } from '@src/constants/amountSettings';
import { PRODUCT_LIST_ANCHOR } from '@src/constants/common';
import { FILTERS_NAMES } from '@src/constants/filters';
import type { ListKeys } from '@src/constants/microcredits';
import { sendShowProductEvent } from '@src/helpers/analyticsEvents';
import { scrollToElementById } from '@src/helpers/common';
import type { IFilter } from '@src/hooks/useFilters';
import { useSelectCreditsList } from '@src/reducers/microcredits';
import { useHasUtmLabel } from '@src/reducers/route';
import { CurrencySymbol } from 'shared/config/internalization';
import { AnchorLinkList } from 'shared/ui';

import { AmountFilterText } from './AmountFilter.config';
import styles from './AmountFilter.module.scss';

interface Props {
  filters: IFilter;
  listKey: ListKeys;

  maxValue?: number;
  inputPostfix?: string;
  afterContent?: ReactNode;
}

export const AmountFilter: FC<Props> = memo(({ filters, inputPostfix, maxValue, listKey, afterContent }) => {
  const isMobile = useIsMobile();
  const hasUtmLabel = useHasUtmLabel();

  const { total: count = 0 } = useSelectCreditsList(listKey);

  const onChange = (value: number) => filters.setFilter(FILTERS_NAMES.AMOUNT, value || '');

  const onShowOffers = () => {
    sendShowProductEvent();

    scrollToElementById(PRODUCT_LIST_ANCHOR);
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <AmountControl
          name={FILTERS_NAMES.AMOUNT}
          className={styles.amount}
          label={AmountFilterText.inputLabel}
          value={filters[FILTERS_NAMES.AMOUNT]}
          max={maxValue}
          onChange={onChange}
          postfix={inputPostfix}
        />

        <Button variant="primary" size={isMobile ? 52 : 60} onClick={onShowOffers} className={styles.button}>
          Показать {count}
        </Button>
      </div>

      {!hasUtmLabel && afterContent}
    </div>
  );
});

AmountFilter.displayName = 'AmountFilter';

AmountFilter.defaultProps = {
  inputPostfix: CurrencySymbol.RUB,
  afterContent: <AnchorLinkList />,
  maxValue: RUSSIA_AMOUNT_FIELD_SETTINGS.max,
};
