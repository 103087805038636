import cn from 'classnames';
import type { ReactNode } from 'react';
import React, { memo } from 'react';

import { Grid } from '@sravni/react-design-system';
import { useIsMobile } from '@sravni/react-utils';

import {
  PageHeaderBranding as PageHeaderBrandingBase,
  PageHeaderBrandingContainer,
  PageHeaderLocation,
  PageHeaderSponsorLink,
  PageHeaderSubHeading,
} from '@src/components/PageHeader';
import { useHeadingTexts } from '@src/components/PageHeader/hooks';
import { useDeviceBranding } from '@src/reducers/branding';

import styles from './BrandingHeaders.module.scss';

interface Props {
  afterHeaders?: ReactNode;
  containerClassName?: string;
}

export const BrandingHeaders = memo(({ afterHeaders, containerClassName }: Props) => {
  const isMobile = useIsMobile();

  const { creditCalculator } = useDeviceBranding() || {};
  const { headingText, subHeadingText } = useHeadingTexts();

  const hasBanner = !!creditCalculator?.backgroundImage?.original;

  if (isMobile) {
    return (
      <section
        className={cn(containerClassName, {
          'h-mt-20': !hasBanner,
        })}
      >
        {afterHeaders}
      </section>
    );
  }

  return (
    <PageHeaderBrandingBase brandingConfig={creditCalculator} className={containerClassName}>
      <PageHeaderBrandingContainer className={styles.page_header_branding_container}>
        <Grid.Row className={cn({ [styles.withBanner]: hasBanner })}>
          <Grid.Col className={styles.location_container}>
            <PageHeaderLocation
              className={cn(styles.location, { [styles.locationWithBanner]: hasBanner })}
              brandingConfig={creditCalculator}
              position="center"
              header={headingText}
            />
            <PageHeaderSubHeading>{subHeadingText}</PageHeaderSubHeading>
          </Grid.Col>
          <Grid.Col className={styles.sponsor_container}>
            <PageHeaderSponsorLink />
          </Grid.Col>
        </Grid.Row>

        {afterHeaders}
      </PageHeaderBrandingContainer>
    </PageHeaderBrandingBase>
  );
});

BrandingHeaders.displayName = 'BrandingHeaders';
