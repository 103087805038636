import { useMemo } from 'react';

import type { IClientMicrocreditListItem } from '@src/@types/clienttypes';
import { ListKeys } from '@src/constants/microcredits';
import { useSelectCreditsList } from '@src/reducers/microcredits';

export const useSidebarBanner = (): IClientMicrocreditListItem => {
  const { items = [] } = useSelectCreditsList(ListKeys.SIDEBAR_BANNER);
  return useMemo(() => items[0], [items]);
};
